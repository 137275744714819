import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "gatsby"
import logo from './thaiis.png';

class Header extends React.Component {

    render() {
        const rootPath = `${__PATH_PREFIX__}/`
        return (
            <header className="header-main">

                <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light"><div className="container">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    {this.props.tagName}
                    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
                        <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" to="/บทวเคราะห">บทวิเคราะห์</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/nhan-dinh-bong-da">Nhận Định Bóng Đá</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/may-tinh-du-doan">Máy Tính Dự Đoán</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/cau-chuyen-bong-da">Câu Chuyện Bóng Đá</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/troll-bong-da">Troll Bóng Đá</Link>
                            </li> */}
                        </ul>
                    </div></div>
                </nav>

            </header>
        )
    }
}

export default Header;